import React from "react";
import { Route, Navigate, Routes } from "react-router-dom";
import { CookiesProvider } from "react-cookie";

import "./App.css";

import routes from "./routes";

function App() {
	return (
		<React.Fragment>
			<CookiesProvider>
				<Routes>
					{routes.map(({ path, name, Component }, key) => (
						<Route exact path={path} key={key} element={<Component />} />
					))}
					<Route path="/" element={<Navigate replace to="/home" />} />
				</Routes>
			</CookiesProvider>
		</React.Fragment>
	);
}

export default App;
