import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-grid-system";
import axios from "axios";
import { MdDone } from "react-icons/md";
import { Oval } from "react-loader-spinner";
import { useCookies } from "react-cookie";

var options = {
	weekday: "long",
	month: "long",
	day: "numeric",
	hour: "numeric",
	minute: "numeric",
};

function Admin() {
	const [users, setUsers] = useState();
	const [filteredUsers, setFilteredUsers] = useState([]);
	const [loading, setLoading] = useState(true);

	const [cookies, setCookie] = useCookies(["admin"]);

	useEffect(() => {
		if (cookies.admin !== "0679386080") {
			const appUsername = prompt("Username");
			const appPassword = prompt("Password");

			if (appUsername === "admin" && appPassword === "triboo") {
				setCookie("admin", "0679386080", {
					path: "/",
					expires: new Date("2023-11-14T18:30:00"),
				});
			} else {
				window.location.href = "/home";
			}
		}

		console.log(process.env.REACT_APP_API_URL + "/users");

		axios
			.get(process.env.REACT_APP_API_URL + "/users/sHp7Qb7Fly")
			.then(function (response) {
				// handle success
				console.log(response);
				setUsers(response.data);
				setFilteredUsers(response.data);
				setLoading(false);
			})
			.catch(function (error) {
				// handle error
				console.log(error);
			});
	}, []);

	const filterUsers = (e) => {
		const keyword = e.target.value;
		const filteredUsers = users.filter(
			(user) =>
				user.name.toLowerCase().includes(keyword.toLowerCase()) ||
				user.surname.toLowerCase().includes(keyword.toLowerCase())
		);
		setFilteredUsers(filteredUsers);
	};

	return (
		<div className="home">
			{cookies.admin === "0679386080" && (
				<Container>
					<Row style={{ paddingTop: 100 }}>
						<Col sm={12}>
							<div className="input-container">
								<input
									type="search"
									placeholder="User"
									onChange={filterUsers}
								/>
							</div>
						</Col>
					</Row>
					<Row style={{ marginTop: 40 }}>
						{!loading && (
							<Col sm={12}>
								<table>
									<thead>
										<tr>
											<th>ID</th>
											<th>Name</th>
											<th>Surname</th>
											<th>Registration Date</th>
											<th>Scan link</th>
											<th>Scan id</th>
											<th>Actions</th>
										</tr>
									</thead>
									<tbody>
										{filteredUsers.map((user) => (
											<tr key={user.id}>
												<td style={{ textAlign: "center" }}>{user.id}</td>
												<td>{user.name}</td>
												<td>{user.surname}</td>
												<td>
													{new Date(user.createdAt).toLocaleDateString(
														"it-IT",
														options
													)}
												</td>
												<td style={{ textAlign: "center" }}>
													{user.scanUrl ? (
														<MdDone size={24} style={{ color: "white" }} />
													) : (
														""
													)}
												</td>
												<td style={{ textAlign: "center" }}>
													{user.scanId ? (
														<MdDone size={24} style={{ color: "white" }} />
													) : (
														""
													)}
												</td>

												<td style={{ textAlign: "right", width: 100 }}>
													<button
														className="btn empty"
														onClick={() => {
															window.location.href = "/admin/" + user.id;
														}}
													>
														EDIT
													</button>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</Col>
						)}
						{loading && (
							<Col sm={12} className="right-col">
								<div className="liberatoria-back"></div>
								<div className="liberatoria">
									<Oval
										height={80}
										width={80}
										color="white"
										wrapperStyle={{ justifyContent: "center" }}
										wrapperClass=""
										visible={true}
										secondaryColor="transparent"
										strokeWidth={2}
										strokeWidthSecondary={2}
									/>
								</div>
							</Col>
						)}
					</Row>
				</Container>
			)}
		</div>
	);
}

export default Admin;
