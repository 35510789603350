import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-grid-system";
import { useParams } from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs";
import axios from "axios";
import { useCookies } from "react-cookie";

function Admin() {
	const [user, setUser] = useState({
		scanUrl: "",
		scanId: "",
	});

	const [submitted, setSubmitted] = useState(false);
	const [success, setSuccess] = useState(false);

	const [cookies, setCookie] = useCookies(["admin"]);

	let { id } = useParams();

	useEffect(() => {
		if (cookies.admin !== "0679386080") {
			const appUsername = prompt("Username");
			const appPassword = prompt("Password");

			if (appUsername === "admin" && appPassword === "triboo") {
				setCookie("admin", "0679386080", {
					path: "/",
					expires: new Date("2023-11-14T18:30:00"),
				});
			} else {
				window.location.href = "/home";
			}
		}

		if (!id) {
			return;
		}

		axios
			.get(process.env.REACT_APP_API_URL + "/users/" + id)
			.then(function (response) {
				// handle success
				console.log(response);
				setUser(response.data);
			})
			.catch(function (error) {
				// handle error
				console.log(error);
			});
	}, []);

	const handleSubmit = () => {
		axios
			.put(process.env.REACT_APP_API_URL + "/users/" + id, user)
			.then(function (response) {
				// handle success
				console.log(response);
				setSubmitted(true);
				setSuccess(true);
			})
			.catch(function (error) {
				// handle error
				console.log(error);
				setSubmitted(true);
				setSuccess(false);
			});

		console.log("submit");
	};

	const handleInput = (e) => {
		setUser((prev) => ({ ...prev, [e.target.name]: e.target.value }));
	};

	return (
		<div className="home">
			<Container>
				<Row style={{ paddingTop: 100 }}>
					<Col sm={12}>
						<button
							className="btn empty"
							onClick={() => {
								window.location.href = "/admin";
							}}
							style={{ padding: "0px 10px" }}
						>
							<BsArrowLeft
								size={28}
								style={{ verticalAlign: "middle", marginRight: 10 }}
							/>
							<span style={{ verticalAlign: "middle" }}>torna indietro</span>
						</button>
					</Col>
				</Row>
				<Row style={{ paddingTop: 60 }}>
					<Col sm={12}>
						<div className="input-container">
							<label>Link scansione</label>
							<input
								name="scanUrl"
								type="text"
								placeholder=""
								value={user.scanUrl}
								onChange={handleInput}
							/>
						</div>
					</Col>
					<Col sm={12} style={{ paddingTop: 20 }}>
						<div className="input-container">
							<label>Id scansione</label>
							<input
								name="scanId"
								type="text"
								placeholder=""
								value={user.scanId}
								onChange={handleInput}
							/>
						</div>
					</Col>
					<Col sm={12} style={{ paddingTop: 40 }}>
						<div className="">
							<button className="btn full" onClick={handleSubmit}>
								SALVA
							</button>
						</div>
					</Col>
					<Col sm={12} style={{ paddingTop: 10 }}>
						<div className="">
							{submitted && success && <p>Utente aggiornato correttamente</p>}
							{submitted && !success && (
								<p>Errore nell'aggiornamento dell'utente</p>
							)}
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	);
}

export default Admin;
