import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-grid-system";
import { useParams } from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs";
import axios from "axios";
import { useCookies } from "react-cookie";

function Admin() {
	const [user, setUser] = useState({
		scanUrl: "",
		scanId: "",
	});

	const [submitted, setSubmitted] = useState(false);
	const [success, setSuccess] = useState(false);

	const [cookies, setCookie] = useCookies(["admin"]);

	let { id } = useParams();

	useEffect(() => {
		if (!id) {
			return;
		}

		axios
			.get(process.env.REACT_APP_API_URL + "/users/" + Math.round(id / 1023))
			.then(function (response) {
				// handle success
				console.log(response);
				setUser(response.data);
			})
			.catch(function (error) {
				// handle error
				console.log(error);
			});
	}, []);

	const handleSubmit = () => {
		axios
			.put(process.env.REACT_APP_API_URL + "/users/" + Math.round(id / 1023), user)
			.then(function (response) {
				// handle success
				console.log(response);
				setSubmitted(true);
				setSuccess(true);
			})
			.catch(function (error) {
				// handle error
				console.log(error);
				setSubmitted(true);
				setSuccess(false);
			});

		console.log("submit");
	};

	const handleInput = (e) => {
		setUser((prev) => ({ ...prev, [e.target.name]: e.target.value }));
	};

	return (
		<div className="home">
			<Container>
				<Row style={{ paddingTop: 60 }}>
					{!submitted && <Col sm={12}>
						<div className="input-container">
							<label>Wallet Address</label>
							<input
								name="walletAddress"
								type="text"
								placeholder=""
								value={user.walletAddress}
								onChange={handleInput}
							/>
						</div>
					</Col>}
					{!submitted && <Col sm={12} style={{ paddingTop: 40 }}>
						<div className="">
							<button className="btn full" onClick={handleSubmit}>
								SALVA
							</button>
						</div>
					</Col>}
          {submitted && <Col sm={12} style={{ paddingTop: 40 }}>
            <div className="">
              {success && <p>The nft request was successful.</p>}
              {!success && <p>Generic error.</p>}
            </div>
          </Col>}

				</Row>
			</Container>
		</div>
	);
}

export default Admin;
