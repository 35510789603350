import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
function Landing3d() {

	let { id } = useParams();

	const [user, setUser] = useState({
		scanUrl: "",
		scanId: "",
	});

	useEffect(() => {
		if (!id) {
			return;
		}

		axios
			.get(process.env.REACT_APP_API_URL + "/users/" + Math.round(id / 1023))
			.then(function (response) {
				// handle success
				console.log(response);
				setUser(response.data);
			})
			.catch(function (error) {
				// handle error
				console.log(error);
			});
	}, []);

	function embedSrc () {
		if(!user.scanUrl) {
			return "";
		}

		let app = "https://sketchfab.com/models/";
		let scanId = user.scanUrl.split("/")[4].split("-").pop();
		return app + scanId + "/embed?autostart=1&preload=1&ui_animations=0&ui_infos=0&ui_stop=0&ui_inspector=0&ui_watermark_link=0&ui_watermark=0&ui_help=0&ui_settings=0&ui_annotations=0&orbit_constraint_zoom_in=2&ui_vr=0&ui_theme=dark&ui_ar=0";
	}


	return (
		<div className="sketchfab-embed-wrapper">
			<div style={{height: "15vh", position: "relative"}}>
				<img className="logo" height="24" src={process.env.PUBLIC_URL + "/logo-triboo.png"} alt="" />
			</div>
			{user.scanUrl && <iframe 
				title="20230518_EPhoto_341_2_1298" 
				frameborder="0" 
				allowfullscreen="" 
				mozallowfullscreen="true" 
				webkitallowfullscreen="true" 
				allow="autoplay; fullscreen; xr-spatial-tracking" 
				xr-spatial-tracking="" 
				execution-while-out-of-viewport="" 
				execution-while-not-rendered="" 
				web-share="" 
				src={embedSrc()}> </iframe> }
		</div>
	);
}

export default Landing3d;
