import { useEffect } from 'react';

const useCanvasResize = (sigPad, setSigPad) => {
  useEffect(() => {
    const saveCanvasState = () => {
      if (sigPad) {
        return sigPad.getTrimmedCanvas().toDataURL();
      }
      return null;
    };

    const restoreCanvasState = (dataURL) => {
      if (sigPad && dataURL) {
        sigPad.fromDataURL(dataURL);
      }
    };

    const handleResize = () => {
      const dataURL = saveCanvasState();
      if (sigPad) {
        sigPad.clear();
      }
      restoreCanvasState(dataURL);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [sigPad, setSigPad]);
};

export default useCanvasResize;