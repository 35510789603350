import Home from "./components/home";
import Admin from "./components/admin";
import AdminEdit from "./components/adminEdit";
import Landing3d from "./components/landing3d";
import Request from "./components/request";

const routes = [
	{
		path: "/home",
		name: "Home",
		Component: Home,
	},
	{
		path: "/admin",
		name: "Admin",
		Component: Admin,
	},
	{
		path: "/admin/:id",
		name: "AdminEdit",
		Component: AdminEdit,
	},
	{
		path: "/twin/:id",
		name: "Landing3d",
		Component: Landing3d,
	},
	{
		path: "/request/:id",
		name: "Request",
		Component: Request,
	},
];

export default routes;
