import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-grid-system";
import SignatureCanvas from "react-signature-canvas";
import axios from "axios";
import { Oval } from "react-loader-spinner";
import useCanvasResize from "../useCanvasResize";

function Home() {
	const [user, setUser] = useState({
		name: "",
		surname: "",
		email: "",
		signatureDisclaimer: "",
		signaturePrivacy: "",
		disclaimer: undefined,
		privacy: undefined,
	});

	const [errors, setErrors] = useState(false);
	const [firstStep, setFirstStep] = useState(true);
	const [secondStep, setSecondStep] = useState(false);
	const [loading, setLoading] = useState(false);
	const [submitted, setSubmitted] = useState(false);
	const [success, setSuccess] = useState(false);
	const [disclaimerChecked, setDisclaimerChecked] = useState(false)
	const [privacyChecked, setPrivacyChecked] = useState(false)

	const [sigPadDisclaimer, setSigPadDisclaimer] = useState(null);
  const [sigPadPrivacy, setSigPadPrivacy] = useState(null);

	useCanvasResize(sigPadDisclaimer, setSigPadDisclaimer);
  useCanvasResize(sigPadPrivacy, setSigPadPrivacy);

	const handleInput = (e) => {
		setUser((prev) => ({ ...prev, [e.target.name]: e.target.value }));
	};

	const validateEmail = (email) => {
    // Regex per validare un'email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };


	// useEffect(() => {
	// 	// forzo il resize della pagina sennò il canvas non funziona
	// 	setTimeout(function () {
	// 		window.dispatchEvent(new Event("resize"));
	// 	}, 100);
	// }, []);

	useEffect(() => {
		// console.log(user);
	}, [user]);

	const clearDisclaimer = () => {
		setSigPadDisclaimer(sigPadDisclaimer.clear());
	};

	const clearPrivacy = () => {
		setSigPadPrivacy(sigPadPrivacy.clear());
	};

	const trimDisclaimer = () => {
		const file = DataURIToBlob(
			sigPadDisclaimer.getTrimmedCanvas().toDataURL("image/png")
		);
		return file;
	};

	const trimPrivacy = () => {
		const file = DataURIToBlob(
			sigPadPrivacy.getTrimmedCanvas().toDataURL("image/png")
		);
		return file;
	};

	useEffect(() => {
    if (sigPadDisclaimer && user.signatureDisclaimer !== "") {
      sigPadDisclaimer.fromDataURL(user.signatureDisclaimer);
    }
  }, [sigPadDisclaimer, user.signatureDisclaimer]);

  useEffect(() => {
    if (sigPadPrivacy && user.signaturePrivacy !== "") {
      sigPadPrivacy.fromDataURL(user.signaturePrivacy);
    }
  }, [sigPadPrivacy, user.signaturePrivacy]);

	const backHome = () => {
		const initialState = {
			name: "",
			surname: "",
			email: "",
			signatureDisclaimer: "",
			signaturePrivacy: "",
			disclaimer: undefined,
			privacy: undefined,
		};
		setUser(initialState);
		setFirstStep(true)
		setSecondStep(false)
		setLoading(false)
		setErrors(false)
		setSubmitted(false)
		setSuccess(false)
	}

	const confirmFirstStep = () => {
		if (
			user.name === "" ||
			user.surname === "" ||
			user.email === ""
		) {
			setErrors(true);
			return;
		}
		if (!validateEmail(user.email)) {
      setErrors(true);
			return
    }
		setErrors(false)
		setFirstStep(false)
		setSecondStep(true)
	}

	const handleSubmit = () => {
		console.log('qui dentro')
		//check dei campi base
		if (
			user.name === "" ||
			user.surname === "" ||
			user.email === "" ||
			user.disclaimer === undefined ||
			user.privacy === undefined
		) {
			setErrors(true);
			return;
		}

		//check del campo signature
		if (sigPadDisclaimer?.isEmpty()) {
			setErrors(true);
			return;
		}

		if (sigPadPrivacy?.isEmpty()) {
			setErrors(true);
			return;
		}

		setLoading(true);

		axios
			.post(process.env.REACT_APP_API_URL + "/users/create", {
				...user,
				signatureDisclaimer: sigPadDisclaimer.getTrimmedCanvas().toDataURL("image/png"),
				signaturePrivacy: sigPadPrivacy.getTrimmedCanvas().toDataURL("image/png"),
			})
			.then(function (response) {
				// handle success
				setFirstStep(false)
				setSecondStep(false)
				setLoading(false);
				setSubmitted(true);
				setSuccess(true);
			})
			.catch(function (error) {
				// handle error
				setFirstStep(false)
				setSecondStep(false)
				setLoading(false);
				setSubmitted(true);
				setSuccess(false);
			});
	};

	function DataURIToBlob(dataURI) {
		const splitDataURI = dataURI.split(",");
		const byteString =
			splitDataURI[0].indexOf("base64") >= 0
				? atob(splitDataURI[1])
				: decodeURI(splitDataURI[1]);
		const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

		const ia = new Uint8Array(byteString.length);
		for (let i = 0; i < byteString.length; i++)
			ia[i] = byteString.charCodeAt(i);

		return new Blob([ia], { type: mimeString });
	}

	return (
		<div className="home">
			<Container className="home-container">
				<Row className="home-container__row">
					<Col sm={12}>
					<img className="logo-art" src={process.env.PUBLIC_URL + "/art_in_the_garden.png"} alt="" />
					</Col>
					{firstStep && (
						<Col sm={12}>
							<Row className="form-container">
								<Col sm={12}>
									<div
										className={
											errors && user.name === ""
												? "input-container error"
												: "input-container"
										}
									>
										<label>Nome</label>
										<input
											type="text"
											id="name"
											name="name"
											value={user.name}
											onChange={handleInput}
										/>
									</div>
								</Col>
								<Col sm={12}>
									<div
										className={
											errors && user.surname === ""
												? "input-container error"
												: "input-container"
										}
									>
										<label>Cognome</label>
										<input
											type="text"
											id="surname"
											name="surname"
											value={user.surname}
											onChange={handleInput}
										/>
									</div>
								</Col>
								<Col sm={12}>
									<div
										className={
											(errors && user.email === "") || (errors && !validateEmail(user.email))
												? "input-container error"
												: "input-container"
										}
									>
										<label>Email</label>
										<input
											type="email"
											id="email"
											name="email"
											value={user.email}
											onChange={handleInput}
										/>
									</div>
								</Col>
								{/* <Col sm={12}>
									<div className="input-container signature">
										<label>Firma</label>
										<div
											className={
												errors && sigPad?.isEmpty()
													? "signature-canvas-wrapper error"
													: "signature-canvas-wrapper"
											}
										>
											<SignatureCanvas
												penColor="black"
												backgroundColor="rgb(255, 255, 255)"
												canvasProps={{
													className: "signature-canvas",
												}}
												clearOnResize={true}
												ref={(ref) => {
													setSigPad(ref);
												}}
											/>
										</div>
									</div>
								</Col> */}
								{/* <Col sm={12} style={{ marginBottom: 63 }}>
									<div className="text-center">
										<button className="btn empty" onClick={clear}>
											CANCELLA LA FIRMA
										</button>
									</div>
								</Col> */}
								{/* <Col sm={12}>
									<div
										className={
											errors && !user.disclaimer
												? "input-container-checkbox error"
												: user.disclaimer
												? "input-container-checkbox active"
												: "input-container-checkbox"
										}
									>
										<label className="container">
											Dichiaro di aver letto, compreso e sottoscritto la
											<span
												onClick={() => {
													setUser({
														...user,
														signature: sigPad
															.getTrimmedCanvas()
															.toDataURL("image/png"),
													});
													//setViewLiberatoria(true);
													setViewPrivacy(true);
												}}
											>
												{" "}
												liberatoria per la realizzazione e il trasferimento di
												una riproduzione del tridimensionale del mio corpo
											</span>
											*
											<input
												type="checkbox"
												id="disclaimer"
												name="disclaimer"
												checked={user.disclaimer}
												onChange={(e) => {
													setUser({
														...user,
														disclaimer: !user.disclaimer,
													});
												}}
											/>
											<span className="checkmark"></span>
										</label>
									</div>
								</Col> */}
								{/* <Col sm={12}>
									<div
										className={
											errors && !user.privacy
												? "input-container-checkbox error"
												: user.privacy
												? "input-container-checkbox active"
												: "input-container-checkbox"
										}
									>
										<label className="container">
											Presto il consenso esplicito al trattamento dei miei dati
											personali appartenenti a categorie particolari come
											indicato nell’
											<span
												onClick={() => {
													setUser({
														...user,
														signature: sigPad
															.getTrimmedCanvas()
															.toDataURL("image/png"),
													});
													setViewPrivacy(true);
												}}
											>
												informativa privacy
											</span>
											*
											<input
												type="checkbox"
												id="privacy"
												name="privacy"
												checked={user.privacy}
												onChange={(e) => {
													setUser({
														...user,
														privacy: !user.privacy,
													});
												}}
											/>
											<span className="checkmark"></span>
										</label>
									</div>
								</Col> */}
								<Col sm={12} className="last-col">
									<div className="">
										<button className="btn full" onClick={confirmFirstStep}>
											CONTINUA
										</button>
									</div>
								</Col>
							</Row>
						</Col>
					)}
					{secondStep && (
						<Col sm={12}>
							<Row className="secondstep-container">
								<Col sm={12}>
									<h2 className="title">AUTORIZZAZIONE E LIBERATORIA ALLA REALIZZAZIONE E TRASFERIMENTO  DI IMMAGINI PERSONALI E VIDEO</h2>
									<h2 className="title">E</h2>
									<h2 className="title">INFORMATIVA PER IL TRATTAMENTO DEI DATI PERSONALI AI SENSI DEL REGOLAMENTO EUROPEO nr. 679/2016 E PER LA PRESTAZIONE DEL CONSENSO</h2>
								</Col>
								<Col sm={12}>
									<p className="description first-description">
										Gentile Signore/a di seguito le esponiamo le informazioni necessarie perché, se lei vorrà, potrà acconsentire alla realizzazione
										di un suo ritratto fotografico e di video che la ritraggono - in occasione dell'evento ART IN THE GARDEN
										(11 luglio 2024, presso sUPERSTUDIO events, via Tortoina 27, 20144 Milano) - nonché prestare un consenso informato alla raccolta
										e archiviazione di suoi dati personali e sensibili.
									</p>
									<p className="description">
										Dando il consenso al seguente documento, autorizza, a titolo gratuito, senza limiti di tempo anche ai sensi degli
										artt. 10 e 320 cod.civ. e degli artt. 96 e 97 legge 22.4.1941, n. 633, Legge sul diritto d'autore, EPHOTO, SUPERSTUDIO SET S.R.L.,
										SUPERSTUDIO EVENTS S.R.L. e SUPERSTUDIO GROUP S.R.L. alla pubblicazione e/o diffusione in qualsiasi forma delle proprie immagini
										sui siti internet aziendali, sui canali social (principalmente Instagram e LinkedIn), su carta stampata e/o su qualsiasi altro
										mezzo di diffusione, nonché autorizza la conservazione delle foto e dei video stessi negli archivi informatici delle aziende
										sopra citate e prende atto che la finalità di tali pubblicazioni sono meramente di carattere informativo ed eventualmente promozionale.
									</p>
									<p className="description">
										La presente liberatoria/autorizzazione potrà essere revocata in ogni tempo con comunicazione scritta da inviare via e-mail.
									</p>
								</Col>
								<Col sm={12}>
									<div className="checkbox-container">
										<div
											className={
												errors && !disclaimerChecked
													? "input-container-checkbox error"
													: disclaimerChecked
													? "input-container-checkbox active"
													: "input-container-checkbox"
											}
										>
											<label className="container">
												Presto il consenso
												<input
													type="checkbox"
													id="disclaimer-true"
													name="disclaimer-true"
													checked={user.disclaimer === true}
													onChange={(e) => {
														setUser({
															...user,
															disclaimer: true,
														});
														setDisclaimerChecked(true)
													}}
												/>
												<span className="checkmark"></span>
											</label>
										</div>
										<div
											className={
												errors && !disclaimerChecked
													? "input-container-checkbox error"
													: disclaimerChecked
													? "input-container-checkbox active"
													: "input-container-checkbox"
											}
										>
											<label className="container">
												Nego il consenso
												<input
													type="checkbox"
													id="disclaimer-false"
													name="disclaimer-false"
													checked={user.disclaimer === false}
													onChange={(e) => {
														setUser({
															...user,
															disclaimer: false,
														});
														setDisclaimerChecked(true)
													}}
												/>
												<span className="checkmark"></span>
											</label>
										</div>
									</div>
								</Col>
								<Col sm={12}>
									<p className="date">Milano, 11 Luglio 2024</p>
								</Col>
								<Col sm={12}>
									<div className="input-container signature">
										<label>In fede</label>
										<div
											className={
												errors && sigPadDisclaimer?.isEmpty()
													? "signature-canvas-wrapper error"
													: "signature-canvas-wrapper"
											}
										>
											<SignatureCanvas
												penColor="black"
												backgroundColor="rgb(255, 255, 255)"
												canvasProps={{
													className: "signature-canvas",
												}}
												ref={(ref) => {
													setSigPadDisclaimer(ref);
												}}
											/>
										</div>
										<div className="text-center">
											<button className="btn empty" onClick={clearDisclaimer}>
												X
											</button>
										</div>
									</div>
								</Col>
							</Row>
							<Row className="secondstep-container">
								<Col sm={12}>
									<h2 className="title">INFORMATIVA SUL TRATTAMENTO DEI DATI PERSONALI</h2>
								</Col>
								<Col sm={12}>
									<p className="description first-description">
									Ai sensi degli artt. 13 del D. Lgs. n. 196/2003, come modificato dal Decreto Legislativo 10 agosto 2018, n. 101 e dell'art. 13 del Regolamento 
									UE n. 2016/679, si informa che il trattamento dei dati personali conferiti con la presente liberatoria saranno trattati con modalità cartacee
									e telematiche nel rispetto della vigente normativa e dei principi di correttezza, liceità, trasparenza e riservatezza; in tale ottica i dati forniti, 
									ivi incluso il ritratto contenuto nelle fotografie suindicate, verranno utilizzati per le finalità strettamente connesse e strumentali alle attività
									come indicate nella stessa liberatoria.
									</p>
									<p className="description">
										Il conferimento del consenso al trattamento dei dati personali è facoltativo. In qualsiasi momento è possibile esercitare tutti i diritti indicati
										dall'articolo 7 del D. Lgs. n. 196/2003, in particolare la cancellazione, la rettifica o l'integrazione dei dati.
									</p>
								</Col>
								<Col sm={12}>
									<div className="checkbox-container">
										<div
											className={
												errors && !privacyChecked
													? "input-container-checkbox error"
													: disclaimerChecked
													? "input-container-checkbox active"
													: "input-container-checkbox"
											}
										>
											<label className="container">
												Presto il consenso
												<input
													type="checkbox"
													id="privacy-true"
													name="privacy-true"
													checked={user.privacy === true}
													onChange={(e) => {
														setUser({
															...user,
															privacy: true,
														});
														setPrivacyChecked(true)
													}}
												/>
												<span className="checkmark"></span>
											</label>
										</div>
										<div
											className={
												errors && !privacyChecked
													? "input-container-checkbox error"
													: privacyChecked
													? "input-container-checkbox active"
													: "input-container-checkbox"
											}
										>
											<label className="container">
												Nego il consenso
												<input
													type="checkbox"
													id="privacy-false"
													name="privacy-false"
													checked={user.privacy === false}
													onChange={(e) => {
														setUser({
															...user,
															privacy: false,
														});
														setPrivacyChecked(true)
													}}
												/>
												<span className="checkmark"></span>
											</label>
										</div>
									</div>
								</Col>
								<Col sm={12}>
									<p className="date">Milano, 11 Luglio 2024</p>
								</Col>
								<Col sm={12}>
									<div className="input-container signature">
										<label>Firma</label>
										<div
											className={
												errors && sigPadPrivacy?.isEmpty()
													? "signature-canvas-wrapper error"
													: "signature-canvas-wrapper"
											}
										>
											<SignatureCanvas
												penColor="black"
												backgroundColor="rgb(255, 255, 255)"
												canvasProps={{
													className: "signature-canvas",
												}}
												ref={(ref) => {
													setSigPadPrivacy(ref);
												}}
											/>
										</div>
										<div className="text-center">
											<button className="btn empty" onClick={clearPrivacy}>
												X
											</button>
										</div>
									</div>
								</Col>
								<Col sm={12} className="last-col">
									<div className="">
										<button className="btn full" onClick={handleSubmit}>
											{loading && (
												<Oval
												height={80}
												width={80}
												color="white"
												wrapperStyle={{ justifyContent: "center" }}
												wrapperClass=""
												visible={true}
												secondaryColor="transparent"
												strokeWidth={2}
												strokeWidthSecondary={2}
												/>
											)}
											{!loading && (
												<span>CONFERMA</span>
											)}
										</button>
									</div>
								</Col>
							</Row>
						</Col>
					)}
					{submitted && success && (
						<Col sm={12}>
							<div className="liberatoria-back"></div>
							<div className="liberatoria">
								<h2>
									LIBERATORIA REGISTRATA <br/> CON SUCCESSO
								</h2>
								<p>Sei pronto a diventare il protagonista di <br/> un'opera d'arte?</p>
							</div>
							<div className="last-col">
								<div className="">
									<button className="btn full" onClick={backHome}>
										HOME
									</button>
								</div>
							</div>
						</Col>
					)}
					{submitted && !success && (
						<Col sm={12}>
							<div className="liberatoria-back"></div>
							<div className="liberatoria">
								<h2>
									ERRORE DURANTE LA REGISTRAZIONE DELLA LIBERATORIA
								</h2>
								<p>
									C'è stato un problema nel processo: puoi compilare nuovamente
									il form oppure chiedere il supporto di un nostro operatore
									presente all'evento.
								</p>
							</div>
							<div className="last-col">
								<div className="">
									<button className="btn full" onClick={backHome}>
										HOME
									</button>
								</div>
							</div>
						</Col>
					)}
					<Col sm={12}>
						<div className="footer">
							<img
								src={process.env.PUBLIC_URL + "/superstudio.png"}
								alt=""
								className="footer-logo"
							/>
							<img
								src={process.env.PUBLIC_URL + "/ephoto.png"}
								alt=""
								className="footer-logo"
							/>
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	);
}

export default Home;
